import styled from "styled-components";

export const Otsikko = styled.h1`
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 16px;
`;

export const Teksti = styled.ul`
  padding: 20px 0 40px 20px;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
`;

export const TeknisetTiedot = styled.div`
  padding: 20px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 26px;
`;

export const Rivi = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 10px;
  background-color: ${props => props.gray ? "#F0F0F0" : "white"};
`;

export const Selite = styled.span`
  font-size: 14px;
  line-height: 20px;
`;

export const Arvo = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  justify-self: end;
  white-space: nowrap;
`;

export const TuoteGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
`;

export const ListItem = styled.li``;
